.myDateTimeInput {
  width: 45%;
  /*max-width: 160px;*/ /*180px*/
  line-height: 2em;
  border: solid 1px #E2E8F0;
  border-radius: 4px;
  padding-left: 10px;
}

.myDateTimeClearButton {
  width:20px;
}
.myDateTimeClearButton::before{
  content: 'X';
}
